import { RandomReveal } from "react-random-reveal"

function Card2(props) {
  return(
    <>
      <div class="grid grid-cols-4 px-8 py-8 bg-[#081F23] items-center">
        <span class="col-span-1 text-[#38CCED] text-6xl font-semibold mb-2">{'++'}</span>
        <span class="col-span-3 text-white text-3xl uppercase">
          <RandomReveal
            class=""
            isPlaying
            duration={1.25}
            revealEasing="easeInQuad"
            characters="Our Solution."
            ignoreCharacterSet={[" "]}
          />
        </span>
      </div>
    </>
  )
}

export default Card2