import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";  
import './styles/index.css';
import { CustomPreloader } from 'react-preloaders';

import Preloader from './views/preloader.js'
import Main from './views/main.js'
import About from './views/about.js'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>
  },
  {
    path: "about",
    element: <About/>
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <>
      {/* <CustomPreloader>
        <Preloader/>
      </CustomPreloader> */}
      <RouterProvider router={router} />
      {/* <Preloader/>  */}

    </>
  </React.StrictMode>
);
