import { RandomReveal } from "react-random-reveal"
import Card5 from '../components/card5.js'
import Card6 from '../components/card6.js'
import Card7 from '../components/card7.js'

function About() {
  return(
    <div class="w-full h-full my-10 px-2 md:py-4 md:px-12 lg:py-6 lg:px-15 xl:px-[18rem]">
        {/* <div class="section h-full w-full flex flex-row justify-center align-middle">
        <div class="text-2xl font-mono text-white">AUDIBLE MOTION</div>
      </div> */}
      <div class="grid grid-cols-8 items-center">
        <img alt="" src="/audible-motion-logo.svg" class="col-span-7"/>
        <div class="w-full flex flex-row space justify-between">
          <a href="/" class="text-white">Home</a>
          <a href="about" class="text-white">About</a>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 grid-auto-rows w-full my-8">
        <div class="grid grid-cols-2 col-span-2w-full items-center my-12">
          <div class="flex flex-col justify-between">
            <div class="font-mono font-[400] text-5xl text-white leading-none uppercase">
              <RandomReveal
                class=""
                isPlaying
                duration={1.05}
                revealEasing="easeInQuad"
                characters="ABOUT US"
                ignoreCharacterSet={[" "]}
              />
            </div>
            {/* <div class="space-y-4">
              <div class="font-sans font-[300] text-xl text-white leading-none w-10/12">Paired with cutting-edge machine learning technologies and recognition algorithms, Audible Motion aims to redefine sign language translation in the era of modern technology.</div>
              <div class="text-[#8974C6]">Scroll to Learn More ↓</div>
            </div> */}
          </div>
          {/* <div class="h-[75vh] text-center text-white bg-cover" style={{background: "url('/image.jpg')", }}></div> */}
        </div>
        <div class="col-span-2 grid grid-cols-3 gap-4">
          <div class="grid grid-cols-1">
            <div class="col-span-2"><Card5/></div>
            <div class="col-span-2 bg-slate-50 w-full h-[35vh]">
              Hello
            </div>
            <div class="font-sans font-[300] text-xl text-white leading-none w-full mt-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </div>
          <div class="grid grid-cols-1">
            <div class="col-span-2"><Card6/></div>
            <div class="col-span-2 bg-slate-50 w-full h-[35vh]">
              Hello
            </div>
            <div class="font-sans font-[300] text-xl text-white leading-none w-full mt-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </div>
          <div class="grid grid-cols-1">
            <div class="col-span-2"><Card7/></div>
            <div class="col-span-2 bg-slate-50 w-full h-[35vh]">
              Hello
            </div>
            <div class="font-sans font-[300] text-xl text-white leading-none w-full mt-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </div>
        </div>
      </div>
      <div class="footer text-white h-[15vh] grid grid-cols-6 pb-[10rem]">
        <div class="col-span-4"><img class="w-[16rem]" alt="" src="/audible-motion-logo.svg"/></div>
        <div class="text-right font-[500] pt-2">Project By</div>
        <div class="text-right pt-2">Curtis Zhou<br/>Philip Han<br/>Ryan Rong</div>
      </div>
    </div>
  )
}

export default About