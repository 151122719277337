import { RandomReveal } from "react-random-reveal"
import Card from '../components/card.js'
import Card2 from '../components/card2.js'
import Card3 from '../components/card3.js'
import Card4 from '../components/card4.js'

function Main() {
  return(
    <div class="w-full h-screen my-10 px-2 md:py-4 md:px-12 lg:py-6 lg:px-15 xl:px-[18rem]" style={{backgroundImage: "url('/bg.jpg')", backgroundSize: "cover"}}>
        {/* <div class="section h-full w-full flex flex-row justify-center align-middle">
        <div class="text-2xl font-mono text-white">AUDIBLE MOTION</div>
      </div> */}
      <div class="grid grid-cols-8 items-center">
        <img alt="" src="/audible-motion-logo.svg" class="col-span-7"/>
        <div class="w-full flex flex-row space justify-between">
          <a href="/" class="text-white">Home</a>
          <a href="about" class="text-white">About</a>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 grid-auto-rows w-full my-8">
        <div class="grid grid-cols-2 col-span-2 h-[75vh] w-full items-center my-12">
          <div class="flex flex-col h-[75vh] justify-between">
            <div class="font-mono font-[400] text-5xl text-white leading-none uppercase">
              <RandomReveal
                class=""
                isPlaying
                duration={1.05}
                revealEasing="easeInQuad"
                characters="THE FUTURE OF"
                ignoreCharacterSet={[" "]}
              />
              <br/>
              <RandomReveal
                class=""
                isPlaying
                duration={1.15}
                revealEasing="easeInQuad"
                characters="SIGN LANGUAGE"
                ignoreCharacterSet={[" "]}
              />
              <br/>
              <RandomReveal
                class=""
                isPlaying
                duration={1.25}
                revealEasing="easeInQuad"
                characters="TRANSLATION"
                ignoreCharacterSet={[" "]}
              />
              <br/>
              <RandomReveal
                class=""
                isPlaying
                duration={1.35}
                revealEasing="easeInQuad"
                characters="IS HERE."
                ignoreCharacterSet={[" "]}
              />
            </div>
            <div class="space-y-4">
              <div class="font-sans font-[300] text-xl text-white leading-none w-10/12">Paired with cutting-edge machine learning technologies and recognition algorithms, Audible Motion aims to redefine sign language translation in the era of modern technology.</div>
              <div class="text-[#8974C6]">Scroll to Learn More ↓</div>
            </div>
          </div>
          {/* <div class="h-[75vh] text-center text-white bg-cover" style={{background: "url('/image.jpg')", }}></div> */}
        </div>
        <div class="col-span-2 grid grid-cols-2 gap-6">
          <div>
            <Card/>
            <img src="/image.jpg"/>
          </div>
          <div>
            <div class="font-sans font-[300] text-xl text-white leading-none w-10/12">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </div>
          <div class="col-span-2 grid grid-cols-2 gap-6">
            <div>
              <Card2/>
              <img src="/image.jpg"/>
            </div>
            <div>
              <div class="font-sans font-[300] text-xl text-white leading-none w-10/12">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div>
          </div>
          <div class="col-span-2 grid grid-cols-2 gap-6">
            <div>
              <Card3/>
              {/* <img src="/image.jpg"/> */}
            </div>
            <div class="col-span-2 bg-slate-50 w-full h-[60vh]">
              Hello
            </div>
            <div class="font-sans font-[300] text-xl text-white leading-none w-full">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
            <div class="font-sans font-[300] text-xl text-white leading-none w-full">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </div>
          <div class="col-span-2 grid grid-cols-2 gap-6">
            <div>
              <Card4/>
              {/* <img src="/image.jpg"/> */}
            </div>
            <div class="col-span-2 bg-slate-50 w-full h-[60vh]">
              POSSIBLE DEMO
            </div>
          </div>
        </div>
      </div>
      <div class="footer text-white h-[15vh] grid grid-cols-6 pb-[10rem]">
        <div class="col-span-4"><img class="w-[16rem]" alt="" src="/audible-motion-logo.svg"/></div>
        <div class="text-right font-[500] pt-2">Project By</div>
        <div class="text-right pt-2">Curtis Zhou<br/>Philip Han<br/>Ryan Rong</div>
      </div>
    </div>
  )
}

export default Main